import React, { useState, useEffect } from 'react';
import ConversationService from '../utils/ConversationService';
import { List, ListItem, ListItemText, Typography, Link } from '@mui/material';

const ConversationList = ({ onSelectConversation, userId }) => {
    const [conversationIds, setConversationIds] = useState([]);

    const handleConversationClick = (conversationId) => (event) => {
        event.preventDefault(); // Prevent default link behavior
        onSelectConversation(conversationId);
    };

    useEffect(() => {
        if (userId) { // Make sure userId is not empty
            const fetchRecentConversations = async () => {
                try {
                    const recentConversations = await ConversationService.getRecentConversations(userId);
                    setConversationIds(recentConversations);
                } catch (error) {
                    console.error('Error fetching recent conversations:', error);
                    // Optionally, set an error state here to display an error message
                }
            };

            fetchRecentConversations();
        }
    }, [userId]); // Dependence on userId

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Recent Conversations
            </Typography>
            <List>
                {conversationIds.map((conversationId, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={
                                <Link href="#" onClick={handleConversationClick(conversationId)} style={{ cursor: 'pointer' }}>
                                    {conversationId}
                                </Link>
                            }
                            secondary="Conversation ID" // Label as secondary text
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default ConversationList;
