import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@mui/material';
import '../ConversationDetail.css';
import ConversationService from "../utils/ConversationService";

const ConversationDetail = ({ conversationId }) => {
    const [conversation, setConversation] = useState([]);

    useEffect(() => {
        const fetchConversation = async () => {
            try {
                const conversationData = await ConversationService.getConversationById(conversationId);
                setConversation(conversationData);
            } catch (error) {
                console.error("Error fetching conversation:", error);
            }
        };

        fetchConversation();
    }, [conversationId]);

    const getMessageStyle = (role) => {
        if (role === 'user') return 'message-bubble user';
        if (role === 'assistant') return 'message-bubble assistant';
        return 'message-bubble system';
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Conversation Detail
            </Typography>
            <div className="conversation-container">
                {conversation.map((message, index) => (
                    <Paper key={index} elevation={3} className={getMessageStyle(message.role)}>
                        <Typography variant="body1">{message.message}</Typography>
                        <Typography variant="caption">{message.formattedDate}</Typography>
                    </Paper>
                ))}
            </div>
        </div>
    );
};

export default ConversationDetail;