import React from 'react';
import { Link, Typography, Container, List, ListItem, ListItemText } from '@mui/material';

const DashboardLinks = () => {
    const links = [
        { name: 'DD Revenue Dashboard', url: 'https://us5.datadoghq.com/dashboard/yus-dm7-5nt/santatalks-revenue?refresh_mode=sliding&from_ts=1700675513071&to_ts=1700761913071&live=true' },
        { name: 'DD Calls Dashboard', url: 'https://us5.datadoghq.com/dashboard/hsd-xjx-iqp/santatalks-calls?refresh_mode=sliding&from_ts=1700675746215&to_ts=1700762146215&live=true' },
        { name: 'RevenueCat Overview', url: 'https://app.revenuecat.com/overview' },
        { name: 'Google Analytics', url: 'https://analytics.google.com/analytics/web/' },
        { name: 'DD Service APM', url: 'https://us5.datadoghq.com/apm/services/santatalks-conversation/operations/servlet.request/resources?dependencyMap=qson%3A%28data%3A%28dependencyLayout%3AhybridView%29%2Cversion%3A%210%29&env=dev&isInferred=false&panels=qson%3A%28data%3A%28%29%2Cversion%3A%210%29&resources=qson%3A%28data%3A%28visible%3A%21t%2Chits%3A%28selected%3Atotal%29%2Cerrors%3A%28selected%3Atotal%29%2Clatency%3A%28selected%3Ap95%29%2CtopN%3A%215%29%2Cversion%3A%211%29&summary=qson%3A%28data%3A%28visible%3A%21t%2Cerrors%3A%28selected%3Acount%29%2Chits%3A%28selected%3Acount%29%2Clatency%3A%28selected%3Alatency%2Cslot%3A%28agg%3A95%29%2Cdistribution%3A%28isLogScale%3A%21f%29%2CshowTraceOutliers%3A%21t%29%2Csublayer%3A%28slot%3A%28layers%3Aservice%29%2Cselected%3Apercentage%29%29%2Cversion%3A%211%29&view=spans&start=1700675856458&end=1700762256458&paused=false' },
        { name: 'DD Serverless (Authorizer Lambda)', url: 'https://us5.datadoghq.com/functions?cloud=aws&entity_view=lambda_functions&panel_end=1700762314927&panel_paused=false&panel_start=1700675914927&selection=aws-lambda-functions%2Bsantatalksauthorizerlambda%2Bus-west-2%2B176471149518&start=1700675886516&end=1700762286516&paused=false' },
        { name: 'Google Play Console', url: 'https://play.google.com/console/u/0/developers/8379357066452876513/app/4972650887554734714/app-dashboard?timespan=thirtyDays' },
        { name: 'Apple App Connect', url: 'https://appstoreconnect.apple.com/apps/6472105973/appstore/ios/version/inflight' },
        { name: 'Twilio Error', url: 'https://console.twilio.com/us1/monitor/logs/debugger/errors' },
        { name: 'OpenAI Usage (personal account)', url: 'https://platform.openai.com/usage' },
        { name: 'OpenAI Status', url: 'https://status.openai.com/' },
        { name: 'ElevenLabs Usage', url: 'https://elevenlabs.io/subscription' },


    ];

    return (
        <Container component="main" maxWidth="sm" sx={{ mt: 8 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Main Dashboards
            </Typography>
            <List>
                {links.map((link, index) => (
                    <ListItem key={index} button component="a" href={link.url} target="_blank">
                        <ListItemText primary={link.name} />
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default DashboardLinks;
