import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { CognitoIdentityServiceProvider } from 'aws-sdk';

const POOL_DATA = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "",
};

const userPool = new CognitoUserPool(POOL_DATA);

const cognitoISP = new CognitoIdentityServiceProvider({
    region: 'us-west-2',
});

const signIn = async (username: string, password: string): Promise<void> => {
    const user = new CognitoUser({ Username: username, Pool: userPool });
    const authDetails = new AuthenticationDetails({ Username: username, Password: password });

    return new Promise((resolve, reject) => {
        user.authenticateUser(authDetails, {
            onSuccess: async (session) => {
                // Role check
                const roleAttr = session.getIdToken().payload['custom:role'];
                if (roleAttr === 'admin') {
                    resolve();
                } else {
                    reject(new Error('Access denied. You must have admin privileges.'));
                }
            },
            onFailure: (err) => reject(err),
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                // Handle new password challenge if needed
            },
        });
    });
};

const isAuthenticated = (): Promise<boolean> => {
    return new Promise((resolve) => {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
            currentUser.getSession((err, session) => {
                if (err) {
                    resolve(false);
                } else {
                    resolve(session.isValid());
                }
            });
        } else {
            resolve(false);
        }
    });
};

const signOut = () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
        currentUser.signOut();
    }
};

const getIdToken = (): Promise<string | null> => {
    return new Promise((resolve) => {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
            currentUser.getSession((err, session) => {
                if (err) {
                    resolve(null);
                } else {
                    const idToken = session.getIdToken().getJwtToken();
                    resolve(idToken);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export default { signIn, signOut, isAuthenticated, getIdToken };
