import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useAuth } from "../utils/AuthContext";
import HomeIcon from '@mui/icons-material/Home'; // Import other icons as needed
import LinkIcon from '@mui/icons-material/Link'; // For Links
import PeopleIcon from '@mui/icons-material/People'; // For Users
import ChatIcon from '@mui/icons-material/Chat'; // For Conversation
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const drawerWidth = 200;

const Sidebar: React.FC = () => {

    const navigate = useNavigate();
    const auth = useAuth();

    const handleLogout = async () => {
        await auth?.logout();
        navigate('/login'); // Redirect to login page or appropriate route
    };


    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <List>
                <ListItem button component={RouterLink} to="/">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button component={RouterLink} to="/links">
                    <ListItemIcon>
                        <LinkIcon />
                    </ListItemIcon>
                    <ListItemText primary="Links" />
                </ListItem>
                <ListItem button component={RouterLink} to="/users">
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItem>
                <ListItem button component={RouterLink} to="/conversation">
                    <ListItemIcon>
                        <ChatIcon />
                    </ListItemIcon>
                    <ListItemText primary="Conversation" />
                </ListItem>
                <ListItem button onClick={handleLogout}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default Sidebar;
