import axios from 'axios';
import CognitoService from "./CognitoService";
const API_HOST = process.env.REACT_APP_API_HOST;

export interface UserResponse {
    accountInitialized: boolean;
    role: string;
    email: string;
    phoneNumber: string;
    userId: string;
    emailVerified: boolean;
    phoneVerified: boolean;
    enabled: boolean;
    accountStatus: string;
    createdTime: string; // Use string for formatted date
    lastUpdatedTime: string; // Use string for formatted date
}

const formatDateString = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust the format as needed
};
const listUsers = async () => {
    // Fetch id_token
    const idToken = await CognitoService.getIdToken();

    if (!idToken) {
        throw new Error('User is not authenticated.');
    }

    // Set the Authorization header with the id_token
    const headers = {
        Authorization: `Bearer ${idToken}`
    };

    try {
        const apiUrl = `${API_HOST}/admin-service/users`;
        console.log(apiUrl);

        const response = await axios.get(apiUrl, { headers });
        console.log(response);
        // Map the Axios response data to the UserResponse structure
        const mappedUsers: UserResponse[] = response.data.map((user: any) => ({
            accountInitialized: user.accountInitialized,
            role: user.role,
            email: user.email,
            phoneNumber: user.phoneNumber,
            userId: user.userId,
            emailVerified: user.emailVerified,
            phoneVerified: user.phoneVerified,
            enabled: user.enabled,
            accountStatus: user.accountStatus,
            createdTime: formatDateString(user.createdTime),
            lastUpdatedTime: formatDateString(user.lastUpdatedTime),
        }));

        console.log(mappedUsers)

        return mappedUsers;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }

};

export default {
    listUsers,
};