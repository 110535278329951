import React, { useState } from 'react';
import ConversationList from '../components/ConversationList';
import ConversationDetail from '../components/ConversationDetail';
import { TextField, Button, Box } from '@mui/material'
import { useLocation } from 'react-router-dom';

const ConversationsPage = () => {
    const location = useLocation();
    const passedUserId = location.state?.userId || '';
    const [userId, setUserId] = useState(passedUserId);
    const [selectedConversationId, setSelectedConversationId] = useState(null);

    const handleUserIdChange = (event) => {
        setUserId(event.target.value);
    };

    const handleSearch = () => {
        // Reset selected conversation when searching for a new user
        setSelectedConversationId(null);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 4 }}>
                <TextField
                    label="User ID"
                    variant="outlined"
                    value={userId}
                    onChange={handleUserIdChange}
                    sx={{ width: 350}}
                />
                <Button variant="contained" onClick={handleSearch}>
                    Search
                </Button>
            </Box>
            <ConversationList
                userId={userId}
                onSelectConversation={setSelectedConversationId}
            />
            {selectedConversationId && (
                <ConversationDetail conversationId={selectedConversationId} />
            )}
        </Box>
    );
};

export default ConversationsPage;
