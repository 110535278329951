import axios from 'axios';
import CognitoService from "./CognitoService";

const API_BASE_URL = process.env.REACT_APP_API_HOST;


interface Message {
    userId: string;
    conversationId: string;
    messageId: string;
    role: 'user' | 'assistant' | 'system';
    message: string;
    timestamp: string;
    formattedDate?: string;
}

interface ConversationResponse {
    [index: number]: Message;
}

const formatDate = (timestampString) => {
    const timestamp = parseInt(timestampString, 10);
    const date = new Date(timestamp);
    return date.toLocaleString(); // Or any other format you prefer
};

const ConversationService = {
    getRecentConversations: async (userId) => {
        try {
            const idToken = await CognitoService.getIdToken();

            if (!idToken) {
                throw new Error('User is not authenticated.');
            }
            const headers = {
                Authorization: `Bearer ${idToken}`
            };

            const apiUrl = `${API_BASE_URL}/admin-service/conversations/user/${userId}/conversation/recent`
            console.log(apiUrl);

            const response = await axios.get(apiUrl, {headers});
            console.log(response);
            return response.data;
        } catch (error) {
            console.error('Error fetching recent conversations:', error);
            throw error;
        }
    },

    getConversationById: async (conversationId) => {
        try {
            const idToken = await CognitoService.getIdToken();

            if (!idToken) {
                throw new Error('User is not authenticated.');
            }
            const headers = {
                Authorization: `Bearer ${idToken}`
            };

            const apiUrl = `${API_BASE_URL}/admin-service/conversations/${conversationId}`
            console.log(apiUrl);
            const response = await axios.get(apiUrl, {headers});
            return response.data.map(message => ({
                ...message,
                formattedDate: formatDate(message.timestamp)
            }));
        } catch (error) {
            console.error('Error fetching conversation details:', error);
            throw error;
        }
    }
};

export default ConversationService;
