import React from 'react';

interface VerificationChipProps {
    verified: boolean;
}

const VerificationChip: React.FC<VerificationChipProps> = ({ verified }) => {
    return (
        <div className={`verification-chip ${verified === true ? 'verified' : 'not-verified'}`}>
            {verified === true ? 'Verified' : 'Not Verified'}
        </div>
    );
};

export default VerificationChip;
