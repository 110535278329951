import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {useAuth} from "../utils/AuthContext";

const HomePage = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    const handleLogout = async () => {
        await auth?.logout();
        navigate('/'); // Redirect to login page
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Home Page
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogout}
                    sx={{ mt: 2 }}
                >
                    Log Out
                </Button>
            </Box>
        </Container>
    );
};
export default HomePage;
