import React, {useEffect, useState} from 'react';
import {Box, Button, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';

import UserService, {UserResponse} from '../utils/UserService';
import VerificationChip from "../components/VerificationChipProps";
import {useNavigate} from "react-router-dom";
import '../index.css'

interface User {
    id: string;
    username: string;
    email: string;
    // Add other fields as needed
}

const ListUsers: React.FC = () => {
    const [users, setUsers] = useState<UserResponse[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserResponse | null>(null); // Store selected user
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchUsers() {
            try {
                const userList = await UserService.listUsers();
                setUsers(userList);
            } catch (error) {
                console.error('Error fetching users:', error);
                setErrorMessage('Failed to fetch user data. Please try again.: ' + error);
            }
        }

        fetchUsers();
    }, []);

    const openModal = (user: UserResponse) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedUser(null);
        setIsModalOpen(false);
    };


    return (
        <div>
            <Typography variant="h4" gutterBottom>User List</Typography>
            {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
            )}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>UserId</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.userId}>
                            <TableCell>{user.userId}</TableCell>
                            <TableCell>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    {user.email}
                                    <VerificationChip verified={user.emailVerified}/>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    {user.phoneNumber}
                                    <VerificationChip verified={user.phoneVerified}/>
                                </div>
                            </TableCell>
                            <TableCell>
                                <Button variant="outlined" onClick={() => openModal(user)}>View Details</Button>
                                <Button
                                    variant="outlined"
                                    style={{marginLeft: '10px'}}
                                    onClick={() => navigate('/conversation', {state: {userId: user.userId}})}
                                >
                                    Review Conversations
                                </Button>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Modal */}
            <Modal open={isModalOpen} onClose={closeModal}>
                <Box className="modal-style">
                    {selectedUser && (
                        <div>
                            <Typography variant="h6" gutterBottom>User Details</Typography>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Username</TableCell>
                                        <TableCell>{selectedUser.userId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Email</TableCell>
                                        <TableCell>{selectedUser.email} <VerificationChip
                                            verified={selectedUser.emailVerified}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Phone Number</TableCell>
                                        <TableCell>{selectedUser.phoneNumber} <VerificationChip
                                            verified={selectedUser.phoneVerified}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Account Initialized</TableCell>
                                        <TableCell><VerificationChip
                                            verified={selectedUser.accountInitialized}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Created Time</TableCell>
                                        <TableCell>{selectedUser.createdTime}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Last Updated Time</TableCell>
                                        <TableCell>{selectedUser.lastUpdatedTime}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Button variant="contained" onClick={closeModal}>Close</Button>
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default ListUsers;
