// src/App.tsx

import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from "./pages/HomePage";
import CognitoService from "./utils/CognitoService";
import Sidebar from "./components/Sidebar";
import { useAuth } from './utils/AuthContext';
import DashboardLinks from "./pages/DashboardLinks";
import UserListPage from "./pages/UserListPage";
import ConversationPage from "./pages/ConversationPage";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();

    if (auth?.isLoading) {
        return <div>Loading...</div>;
    }

    if (!auth?.isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return children;
}
const App: React.FC = () => {
    const auth = useAuth();

    return (
        <Router>
            {auth?.isAuthenticated && <Sidebar />}
            <main style={{ marginLeft: auth?.isAuthenticated ? 240 : 0 }}>
                <Routes>
                    {auth?.isAuthenticated ? (
                        <Route>
                            <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                            <Route path="/links" element={<ProtectedRoute><DashboardLinks /></ProtectedRoute>} />
                            <Route path="/users" element={<ProtectedRoute><UserListPage /></ProtectedRoute>} />
                            <Route path="/conversation" element={<ProtectedRoute><ConversationPage /></ProtectedRoute>} />
                            <Route path="*" element={<Navigate to="/home" />} />
                        </Route>
                    ) : (
                        <>
                            <Route path="/login" element={<LoginPage />} />
                            {/* ... other unauthenticated routes */}
                            <Route path="*" element={<Navigate to="/login" />} />
                        </>
                    )}
                </Routes>
            </main>
        </Router>
    );

};
export default App;