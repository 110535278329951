import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // If you have a global stylesheet, adjust the path as needed
import App from './App'; // Adjust the path if your App component is located elsewhere
import { AuthProvider } from './utils/AuthContext';

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
